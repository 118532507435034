export default function Datos() {
  return (
    <>
      <div className="flex justify-around h-full p-3 bg-[#ea1300]">
        <div className="flex flex-col justify-center">
          <span className="font-extrabold text-[7.7vw] sm:text-[7vw] md:text-[5.6vw] xl:text-[4.8vw] text-center text-[#fff]">100%</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">Control de</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">tu empresa</span>
        </div>
        <div className="flex flex-col justify-center">
          <span className="font-extrabold text-[7.7vw] sm:text-[7vw] md:text-[5.6vw] xl:text-[4.8vw] text-center text-[#fff]">40%</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">Ahorro</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">en procesos</span>
        </div>
        <div className="flex flex-col justify-center">
          <span className="font-extrabold text-[7.7vw] sm:text-[7vw] md:text-[5.6vw] xl:text-[4.8vw] text-center text-[#fff]">100%</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">Optimización en</span>
          <span className="text-[3.4vw] sm:text-[2.5vw] md:text-[2vw] xl:text-[1.8vw] text-center font-light text-[#fff]">carta porte</span>
        </div>
        
      </div>
    </>
  )
}