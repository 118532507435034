import { useState, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { Card, Typography } from "@material-tailwind/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm, ValidationError } from '@formspree/react';

export default function ModalDemo() {
  let [isOpen, setIsOpen] = useState(false)
  const [state, handleSubmit] = useForm("xlekozpl");
  if (state.succeeded) {
      return  <span 
                className="text-[#fff] font-semibold rounded-3xl bg-[#ea1300] w-48 h-10  items-center justify-center mr-4 hidden lg:flex">
                Gracias!
              </span>;
  } 

  if (state.succeeded && window.screen.width <= 1023 ) {
    return  <span 
              className="text-[#6b6b6b] font-semibold  w-48 h-10  items-center justify-center mr-4">
              Gracias!
            </span>;
  } 

  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }

  return(
    <div>
      <div className="flex flex-col sm:flex-row gap-4">
        <button className="mr-4" onClick={openModal}>
            <span className="flex text-lg md:text-xl text-[#fff] font-semibold rounded-3xl bg-[#ea1300] w-[10rem] md:w-48 h-7 md:h-10 items-center justify-center">Solicitar demo</span>
        </button>
        <a href="https://ecommerce.karggu.com/#!/signin" className="flex text-lg md:text-xl text-[#fff] font-semibold rounded-3xl bg-[#000] w-[10rem] md:w-48 h-7 md:h-10 items-center justify-center">Acceder</a>
      </div>
      {/* <button className="mr-4 lg:hidden" onClick={openModal}>
          <span className="flex text-xl text-[#333] font-semibold w-48 h-10  items-center">Solicitar demo</span>
      </button> */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-0 lg:p-4 text-center backdrop-blur bg-[#23465cb8]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex justify-end w-[99%] lg:w-full h-[85vh] max-w-lg lg:max-w-3xl transform overflow-hidden rounded-3xl bg-[#f0f0f0;] text-left align-middle shadow-xl transition-all">
                  <div className="w-1/3 hidden lg:flex "><img src="https://uploads-ssl.webflow.com/62efd8600e99ef30950239a5/62fd17594783a9ff1d72ccdd_popup-left-bg-image.webp" alt="cars" className="h-full w-full"/></div>
                  <div className="w-2/3 flex flex-col justify-center items-center lg:items-end p-0 sm:p-2 lg:p-6">
                    <Card color="transparent" shadow={false}>
                      <Typography variant="h4" className="text-[#333] mt-4">
                        Con Karggu ahorrarás tiempo y profesionalizarás tu empresa de transporte y envíos.
                      </Typography>
                      <Typography color="gray" className="mt-1 font-normal text-[#707070]">
                        En breve nos pondremos en contacto contigo.
                      </Typography>
                      <form onSubmit={handleSubmit} className="mt-4 mb-2 w-80 max-w-screen-lg sm:w-96">
                        <div className="mb-4 flex flex-col gap-1">
                          <label className="text-sm text-[#333]">Nombre <span className="text-xs text-[#333]">*</span></label>
                          <input name="nombre" id="nombre" className="h-9 w-[98%] p-2 border rounded-md border-[#bcbcbcd0] text-[#333] text-sm" required/>
                          <label htmlFor="email" className="mt-4 text-sm text-[#333]">Email <span className="text-xs text-[#333]">*</span></label>
                          <input type="email" name="email" id="email" className="h-9 w-[98%] p-2 border rounded-md border-[#bcbcbcd0] text-[#333] text-sm" required/>
                          <ValidationError prefix="Email" field="email" errors={state.errors} />
                          <label className="mt-4 text-sm text-[#333]">Número Whatsapp <span className="text-xs text-[#333]">*</span></label>
                          <input type="number" name="teléfono" id="telefono" className="h-9 w-[98%] p-2 border rounded-md border-[#bcbcbcd0] text-[#333] text-sm" required/>
                          
                          <h3 className="text-[#333] mt-3" name="Puesto" id="puesto">Puesto:</h3>
                          <select className="text-sm text-[#333] h-7 rounded-md border border-[#bcbcbcd0] p2">
                            <option value="">Selecciona una opción</option>
                            <option value="director" name="Director" id="director">Director</option>
                            <option value="operacion" name="Operación" id="operación">Operación</option>
                            <option value="finanzas" name="Finanzas" id="finanzas">Finanzas</option>
                            <option value="compras" name="Compras" id="compras">Compras</option>
                            <option value="tecnologia" name="Tecnología" id="tenología">Tecnología</option>
                          </select>

                          <h3 className="text-[#333] mt-3" name="Servicios" id="servicios">¿Tienes cuenta con alguna paquetería?</h3>
                          <div className="flex flex-col">
                            <div className="flex">
                              <div className="flex">
                                <input type="checkbox" name="Fedex" id="fedex" className="accent-[#ea1300]"/>
                                <span className="text-xs text-[#333] ml-1 mr-3">Fedex</span>
                              </div>
                              <div className="flex">
                                <input type="checkbox" name="DHL" id="dhl" className="accent-[#ea1300]"/>
                                <span className="text-xs text-[#333] ml-1 mr-3">DHL</span>
                              </div>
                              <div className="flex">
                                <input type="checkbox" name="Estafeta" id="estafeta" className="accent-[#ea1300]"/>
                                <span className="text-xs text-[#333] ml-1 mr-3">Estafeta</span>
                              </div>
                            </div>
                            <div className="flex mt-3">
                              <div className="flex">
                                <input type="checkbox" name="99minutos" id="99minutos" className="accent-[#ea1300]"/>
                                <span className="text-xs text-[#333] ml-1 mr-3">99minutos</span>
                              </div>
                              <div className="flex">
                                <input type="checkbox" name="Otro" id="otro" className="accent-[#ea1300]"/>
                                <span className="text-xs text-[#333] ml-1">OTROS</span>
                              </div>

                            </div>
                          </div>

                          <h3 className="text-[#333] mt-4" name="Envíos" id="envios">¿Cúantos envíos hacen al mes?</h3>
                          <div className="flex">
                            <div className="flex">
                              <input type="checkbox" name="-100" id="-100" className="accent-[#ea1300]"/>
                              <span className="text-xs text-[#333] ml-1 mr-4">50-100</span>
                            </div>
                            <div className="flex">
                              <input type="checkbox" name="-300" id="-300" className="accent-[#ea1300]"/>
                              <span className="text-xs text-[#333] ml-1 mr-4">100-300</span>
                            </div>
                            <div className="flex">
                              <input type="checkbox" name="+300" id="+300" className="accent-[#ea1300]"/>
                              <span className="text-xs text-[#333] ml-1">+ 300</span>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit" className="mt-2 inline-flex justify-center rounded-md  border-transparent bg-blue-100 px-4 py-2 text-sm font-medium bg-[#ff0000] text-[#fff] hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" 
                          disabled={state.submitting}
                          // onClick={closeModal}
                        >
                          Enviar
                        </button>
                      </form>
                    </Card>
                    <div className="mt-4">
                    </div>
                  </div>
                  <XMarkIcon className="h-6 w-6 text-[#707070] mt-6 mr-6 cursor-pointer" onClick={closeModal}/>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}