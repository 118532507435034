export default function Ahorrar() {
  return (
    <>
      <div className="flex flex-col justify-center h-full p-2 bg-[#000]">
        <div className="flex flex-col justify-center items-center gap-5">
          <span className="text-[8vw] sm:text-5xl md:text-[5.5vw] lg:text-[4.9vw] xl:text-[3.8vw] text-center font-thin text-[#fff]">
            <strong className="font-extrabold">Comienza </strong>a ahorrar
          </span>
          <h2 className='text-[#ea1300] text-[7vw] md:text-[5vw] xl:text-[3.3vw] font-extrabold'>Conoce Karggu</h2>
        </div>
      </div>
    </>
  )
}