import magento from '../../img/magento.png';
import woocommece from '../../img/woocommerce.png';
import shopify from '../../img/shopify.png';
import vtex from '../../img/vtex.png';
import isoRojo from '../../svg/kargguIsotipo_rojo.svg';
import dhl from '../../img/dhl.png';
import minutos from '../../img/minutos.png';
import estafeta from '../../img/estafeta.png';
import fedex from '../../svg/fedex.svg'

export default function Presentation() {
  return(
    <div className="flex flex-col h-full pb-16 bg-karggu-opacity bg-no-repeat bg-cover bg-center shadow-md"> 
      <div className="flex flex-col justify-center items-center mt-5">
        <div><h3 className="text-[5vw] text-[#000]">¿Cómo funciona?</h3></div>
        <div className="w-3/5 text-center">
          <p className='text-[2.5vw] md:text-[2vw]'>
            Automatiza la generación de guías de envío, de tus propias cuentas, 
            con las principales paqueterías de México.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:justify-center items-center mt-5 mr-[2vw] gap-10">
        {/* 1 column */}
        <div className="flex flex-col gap-5">
          {/* 1 top */}
          <div className='flex items-center'>
            <div className="w-[22vw] md:w-[14vw] h-full md:h-[9vw] p-3 shadow-xl flex flex-col justify-center items-center text-center rounded-l-lg bg-[#fff]">
              <h4 className="font-bold text-[2.9vw] md:text-[1.8vw]">$800</h4>
              <span className="font-medium text-[2.3vw] md:text-[1.2vw]">inscripción</span>
              <span className="font-medium inline-block text-[2.2vw] md:text-[1.2vw]">único pago</span>
            </div>
            <div className="w-[26vw] md:w-[18vw] h-full md:h-[19vw] p-3 sm:p-4 md:p-5 shadow-xl flex flex-col items-center justify-center rounded-lg bg-[#fff] gap-4 sm:gap-5 md:gap-8">
              <div className="flex justify-center items-center">
                <img src={shopify} alt="shopify" className='w-[18vw] h-[5vw] md:h-[3vw]'/>
              </div>
              <div className="flex justify-center items-center">
                <img src={magento} alt="magento" className='w-[18vw] h-[5vw] md:h-[3vw]'/>
              </div>
              <div className="flex justify-center items-center">
                <img src={woocommece} alt="woocommerce" className='w-[19vw] h-[5vw] md:h-[4vw]]'/>
              </div>

            </div>
          </div>
          {/* 1 bottom */}
          <div className='flex items-center'>
            <div className="w-[22vw] md:w-[14vw] h-full md:h-[9vw] p-3 shadow-xl flex flex-col justify-center items-center text-center rounded-l-lg bg-[#fff]">
              <h4 className="font-bold text-[2.8vw] md:text-[1.8vw]">$5000</h4>
              <span className="font-medium text-[2.3vw] md:text-[1.2vw]">mensualidad</span>
            </div>
            <div className="w-full h-full flex justify-center shadow-xl rounded-lg bg-[#fff]">
              <div className="flex justify-center items-center p-2 md:p-6 w-full h-full md:w-[15vw]">
                <img src={vtex} alt="vtex" className='w-[18vw] md:w-[10vw] md:h-[4vw]'/>
              </div>
            </div>
          </div>
        </div>
        {/* 2 column */}
        <div className="flex flex-col justify-center">
          <div className="flex justify-center items-center">
            <img src={isoRojo} alt="karrgu isotipo" className='w-[18vw] sm:w-[14vw] md:w-[8.5vw]'/>
          </div>
          <div className="text-center"><h4 className='text-[3vw] md:text-[2vw] font-semibold'>Desde $3 + IVA</h4></div>
          <div className="text-center"><span className='text-[4vw] sm:text-[3.8vw] md:text-xl font-medium'>Guía generada</span></div>
        </div>
        {/* 3 column */}
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center w-[40vw] sm:w-[37vw] md:w-[18vw] h-full md:h-[26vw] shadow-xl bg-white p-9">
            <img src={estafeta} alt="estafeta"/>
            <img src={dhl} alt="dhl"/>
            <img src={minutos} alt="minutos"/>
            <img src={fedex} alt="fedex" className='mt-7 mb-7 h-[4.5vw] md:h-[2.7vw]'/>
            <span className='text-[2vw] md:text-[1.3vw] font-semibold'>Y AGREGANDO...</span>
          </div>
          <div className='flex flex-col mt-4'>
            <span className='font-medium text-end'>*El costo del servicio Karggu</span>
            <span className='font-medium text-end'>es adicional al costo de la</span>
            <span className='font-medium text-end'>guía de cada paquetería.</span>
            <span className='font-medium text-[14px] text-end'>*Consumo mínimo</span>
            <span className='font-medium text-[14px] text-end'>$400.00 mn mensual.</span>
          </div>
        </div>
      </div>
    </div>
  )
}