import img2 from '../svg/pig.svg'

export default function Imagen2() {

  return(
    <>
      <div className="flex h-full flex-col-reverse justify-center items-center md:flex-row md:justify-evenly mt-10 mb-10 md:mt-0 md:mb-0">
        <div className='w-3/4 md:w-[55%] flex justify-end items-center'>
          <p className='ml-5 md:ml-0 mb-8 md:mb-0 text-[5vw] sm:text-[4vw] md:text-[4vw] lg:text-[3.8vw] xl:text-[3.2vw] md:w-[80%] w-full'>
            <strong className='text-[#000]'>Ahorra tiempo</strong> en tu empresa brindando tecnología a tu equipo de ventas, operación y transporte.
          </p>
        </div>
        <div className='w-full md:w-[45%] flex justify-center md:justify-center items-center mt-7 md:mt-0'>
          <img src={img2} alt="dashboard" className='flex items-center rounded-xl w-3/5 mb-7 md:mb-0 sm:w-[55%] md:w-[73%] md:h-[65%]'/>
        </div>
      </div>
    </>
  )
}