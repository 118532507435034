import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './components/Landing';
import Ecommerce from './components/ecommerce/Ecommerce';
import PorqueKarggu from './components/Nav/PorqueKarggu';
import Precios from './components/Nav/Precios';
import Experiencia from './components/Nav/Experiencia';
import Blog from './components/Nav/Blog';

import './styles/index.css'

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Landing/>}/>
            <Route path='/ecommerce' element={<Ecommerce/>}/>
            <Route path='/porquekarggu' element={<PorqueKarggu/>}/>
            <Route path='/precios' element={<Precios/>}/> 
            <Route path='/experiencia' element={<Experiencia/>}/>
            <Route path='/blog' element={<Blog/>}/>
        </Routes>
    </BrowserRouter>
)

export default App;