import ModalDemo from './ModalDemo';
import truck from '../svg/truck.svg'

export default function Home () {

    return(
        <div className="w-full h-[50%] flex flex-col-reverse md:flex-row sm:h-full md:h-full md:mt-6">
            <div className="w-[95%] h-full md:w-[65%] md:h-full flex flex-col justify-center mt-4 md:pt-6">
                <h1 className="text-[6.5vw] sm:text-[5.5vw] md:text-[4.8vw] xl:text-[4vw] w-[95%] md:w-[90%] font-extralight text-[#000] pl-10 md:pl-[5rem]">Digitalizamos tus</h1>
                <h1 className="text-[6.5vw] sm:text-[5.5vw] md:text-[4.8vw] xl:text-[4vw] w-[100%] font-extralight text-[#000] pl-10 md:pl-[5rem]">operaciones logisticas</h1>
                <div className='flex w-[22%] h-1 ml-[2.5rem] md:ml-[5rem] mt-4 bg-[#ea1300]'></div>
                <div className='flex flex-col ml-[2.5rem] md:ml-[5rem] mt-4 mb-2 md:mb-0'>
                    <p className='text-[2.8vw] sm:text-[1.8vw] lg:text-[1.2vw] text-[#5e5e5e] font-semibold '>
                        Karggu es la plataforma unificadora de operaciones desde la creación de la 
                        guía digital, carta porte, ruteo, status de entrada y salida del almacén 
                        hasta la entrega final con confirmación para tu empresa de transporte.
                    </p>
                </div>
                <div className="flex justify-start ml-[2.5rem] md:ml-[5rem] mt-3 mb-6 md:mt-7 lg:mt-14">
                    <ModalDemo />
                </div>
            </div>
            <div className='flex h-[50%] items-center justify-center md:mr-10 md:h-full'>
                <img src={truck} alt="truck" className='max-h-[65vh] md:max-h-[100vh] w-[85vh] h-full md:w-[93vh]'/>
            </div>
        </div>
    )
}