import React from 'react';
import Nav from '../Nav';
import HomeEcommerce from './HomEcommerce';
import Presentation from './Presentation';
// import Schema from './Schema';
// import Facturacion from './Facturacion';
import Footer from '../Footer';

import LogoPartners from '../../img/KargguPartnes_blanco.png';

export default function Ecommerce() {
  return(
    <div className="flex flex-col">
      <Nav/>
      <HomeEcommerce />
      <div className='flex justify-center items-center h-full mt-7 p-2 bg-[#000000] rounded-bl-[350px]'>
        <div className="flex flex-col justify-center items-center w-full h-full">
          <img src={LogoPartners} alt="logo Karggu" className='w-[38vw] h-[15vw] sm:w-[31vw] sm:h-[13vw] lg:w-[22vw] lg:h-[8vw]' />
        </div>
      </div>
      <Presentation/>
      {/* <Schema/>
      <Facturacion/> */}
      <Footer/> 
    </div>
  )
}