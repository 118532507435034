import { useState, useEffect } from 'react';
import ModalDemo from './ModalDemo';
import DemoEcommmerce from '../components/ecommerce/DemoEcommerce';
import Karggu from '../svg/LogotipoKarggu_rojo.svg';
import whatsapp from '../svg/wa.svg';

export default function Footer() {

  const [ecommerce, setEcommerce] = useState(false);

  useEffect(() => {
    if (window.location.href === 'https://karggu.com/ecommerce') {
      setEcommerce(true);
    }
  }, []);


  return(
    <>
      <div>
        <a 
          href="https://api.whatsapp.com/send?phone=2211203777&text=Hola%20Karggu,%20me%20gustaría%20simplificar%20los%20procesos%20de%20carta%20porte,%20operación%20y%20profesionalizar%20mi%20empresa%20de%20transporte."
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={whatsapp} alt="whatsapp" className='w-16 md:w-20 h-16 md:h-20 fixed bottom-5 right-5'/>
        </a>
      </div>
      <div className="flex flex-col h-full md:mt-14">
        <div className="flex flex-col p-8 md:flex-row justify-around h-5/6">
          <a href="/" className='flex justify-start items-center'><img src={Karggu} alt="Karggu logo" className='w-48 mb-5 md:w-64'/></a>
          <div className='flex flex-col justify-center md:items-center gap-7'>
            <div className='flex flex-col sm:flex-row gap-9'>
              {ecommerce === false ? (<ModalDemo />) : (<DemoEcommmerce/>) }
              <a href="##"><span className='text-[1.1rem] text-[#333] sm:text-[1.3rem]'>Nosotros</span></a>
              <a href="##"><span className='text-[1.1rem] text-[#333] sm:text-[1.3rem]'>Precios</span></a>
            </div>
            <div className='flex flex-col sm:flex-row gap-9'>
            <a href="##"><span className='text-[1.1rem] text-[#333] sm:text-[1.3rem]'>Blog</span></a>
              <a target="_blank" rel="nofollow noreferrer" href="https://policies.google.com/privacy"><span className='text-[1.1rem] text-[#333] sm:text-[1.3rem]'>Privacidad</span></a>
              <a target="_blank" rel="nofollow noreferrer" href="https://policies.google.com/privacy"><span className='text-[1.1rem] text-[#333] sm:text-[1.3rem]'>Terminos y Condiciones</span></a>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center h-1/6 md:mt-8">
          <span className='text-[.9rem]'>Karggu 2023. Todos los derechos reservados</span>
        </div>
      </div>
    </>
  )
}