import computer from '../../img/homeEcommerce.png';

export default function HomeEcommerce () {

    return(
        <div className="w-full h-full flex flex-col-reverse items-center md:flex-row mb-2 md:mb-20 md:mt-10">
            <div className="w-[95%] h-full md:w-[60%] flex flex-col justify-center">
                <h1 className="text-[5.6vw] sm:text-[4.8vw] md:text-[4.1vw] xl:text-[3.3vw] w-[95%] md:w-[90%] font-extralight text-[#000] pl-10 md:pl-[5rem]">Controla los envíos</h1>
                <h1 className="text-[5.6vw] sm:text-[4.8vw] md:text-[4.1vw] xl:text-[3.3vw] w-[100%] font-extralight text-[#000] pl-10 md:pl-[5rem]">de tus propias cuentas</h1>
                <span className="text-[3.6vw] sm:text-[2.6vw] md:text-[2.4vw] xl:text-[2.1vw] font-extralight  text-[#000] pl-10 md:pl-[5rem]">Con Karggu Ecommerce puedes</span>
                <span className="text-[3.6vw] sm:text-[2.6vw] md:text-[2.4vw] xl:text-[2.1vw] font-extralight text-[#000] pl-10 md:pl-[5rem]">conectar tus propias cuentas de</span>
                <span className="text-[3.6vw] sm:text-[2.6vw] md:text-[2.4vw] xl:text-[2.1vw] font-extralight text-[#000] pl-10 md:pl-[5rem]">paquetaría con tu tienda</span>
                <span className="text-[3.6vw] sm:text-[2.6vw] md:text-[2.4vw] xl:text-[2.1vw] font-extralight text-[#000] pl-10 md:pl-[5rem]">en línea de forma</span>
                <span className="text-[3.6vw] sm:text-[2.6vw] md:text-[2.4vw] xl:text-[2.1vw] font-extralight text-[#000] pl-10 md:pl-[5rem]">sencilla y profesional</span>
                {/* <div className="flex justify-start ml-[2.5rem] md:ml-[5rem] mt-3 mb-2 md:mt-10 lg:mt-16">
                    <DemoEcommerce />
                </div> */}
            </div>
            <div className='flex h-[50%] items-center justify-center md:h-full md:w-[50%]'>
                <img src={computer} alt="truck" className='h-[99%] max-w-[70%] md:max-w-[100%] w-[100%] '/>
            </div>
        </div>
    )
}