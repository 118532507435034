import Nav from './Nav';
import Home from './Home';
import Datos from './Datos';
import Grafica from './Grafica.jsx';
import Imagen2 from './Imagen2';
// import Carousel from './Carousel';
import Smartphone from './Smartphone';
import Ahorrar from './Ahorrar';
import Footer from './Footer';

export default function Landing() {
  return(
    <>
      <Nav/>
      <Home/>
      <Datos/>
      <Grafica/>
      <Imagen2/>
      <Smartphone/>
      <Ahorrar/>
      <Footer/>
    </>
  )
}