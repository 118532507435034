import dash from '../svg/dashboard.svg'

export default function Grafica() {

  return(
    <>
      <div className="flex h-full mt-10 flex-col justify-evenly items-center md:justify-around md:flex-row">
        <div className='w-full md:w-[55%] flex justify-center items-center'>
          <img src={dash} alt="dashboard" className='w-[75%] md:h-[60%] flex items-center'/>
        </div>
        <div className='w-3/4 md:w-[45%] flex items-center justify-center mt-10 md:mt-0'>
          <p className='ml-5 md:ml-0 text-[5vw] sm:text-[4vw] md:text-[3.8vw] xl:text-[3.5vw] w-full md:w-[84%]'>
            <strong className='text-[#000]'>Controla y profesionaliza</strong> toda tu empresa de paquetería y transporte  con la plataforma Karggu.
          </p>
        </div>
      </div>
    </>
  )
}