import { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import KargguLogo from "../svg/LogotipoKarggu_rojo.svg";
import ModalDemo from './ModalDemo';
import DemoEcommerce from '../components/ecommerce/DemoEcommerce';
  
export default function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [ecommerce, setEcommerce] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const boxShadow = hasScrolled ? '0 6px 11px rgba(0, 0, 0, 0.1)' : '';

  useEffect(() => {
    if (window.location.href === 'https://karggu.com/ecommerce') {
      setEcommerce(true);
    }
  }, []);

  // const ecommerceLink = () => {
  //   window.location = '/ecommerce'
  // }

  const porqueKargguLink = () => {
    window.location = '/porquekarggu'
  }
  
  const preciosLink = () => {
    window.location = '/precios'
  }

  const experienciaLink = () => {
    window.location = '/experiencia'
  }

  const blogLink = () => {
    window.location = '/blog'
  }

  return (
    <header className="bg-[#ffff]  sticky top-0 opacity-[.98]" style={{boxShadow}}>
      <nav className="mx-auto h-24 flex max-w-7xl items-center justify-between p-6 lg:px-8 " aria-label="Global">
        <div className="flex lg:flex-1">
            <span className="sr-only">Karggu</span>
            <a href="/"><img className="h-16 w-auto" src={KargguLogo} alt="Karggu logo" /></a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-[#ea1300]"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-12 w-12" aria-hidden="true" />
          </button>
        </div>
        <div className='hidden lg:flex gap-4 xl:gap-6 mr-4 xl:mr-8 ooop'>
          {ecommerce === false ? (
            <button
              onClick={porqueKargguLink}
              className='text-[17px] font-semibold text-[#333]'
            >
              Por qué Karggu?
            </button>
          ): null}
          {ecommerce === false ? (
            <button
              onClick={preciosLink}
              className='text-[17px] font-semibold text-[#333]'
            >
              Precios
            </button>
          ): null}
          {ecommerce === false ? (
            <button
              onClick={experienciaLink}
              className='text-[17px] font-semibold text-[#333]'
            >
              Experiencia
            </button>
          ): null}
          {ecommerce === false ? (
            <button
              onClick={blogLink}
              className='text-[17px] font-semibold text-[#333]'
            >
              Blog
            </button>
          ): null}
        </div>
        <div className='hidden lg:flex'>
          {/* {ecommerce === false ? (
            <button 
                onClick={ecommerceLink} 
                className="flex text-lg md:text-xl text-[#fff] font-semibold rounded-3xl bg-[#000] w-[10rem] md:w-48 h-7 md:h-10 items-center justify-center mr-4"
            >
                e-commerce
            </button>
          ) : null } */}
          { ecommerce === false ? null : <DemoEcommerce/> }  
          
          {ecommerce === false ? ( 
            <a 
              href="https://admin.karggu.com" target="_blank" rel="noreferrer" 
              className="flex text-lg md:text-xl text-[#fff] font-semibold rounded-3xl bg-[#ea1300] w-[10rem] md:w-48 h-7 md:h-10 items-center justify-center">
              Acceder
            </a>
          ) : null }
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <span className="sr-only">Your Company</span>
            <img
              className="h-12 w-auto"
              src={KargguLogo}
              alt="Karggu Logo"
            />
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-7 w-8 text-[#2b2b2b]" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="mt-3 -my-6 divide-y divide-gray-500/10"> 
              <div className='flex flex-col mt-8'>
                <div className="space-y-2">
                  {ecommerce === false ?
                    <ModalDemo />
                  : 
                    <DemoEcommerce/>
                  }
                </div>
                <div className="space-y-2 py-6">
                  {/* {ecommerce === false ? (
                    <button 
                      onClick={ecommerceLink} 
                      className="flex text-lg md:text-xl text-[#fff] font-semibold rounded-3xl bg-[#000] w-[10rem] md:w-48 h-7 md:h-10 items-center justify-center mr-4"
                    >
                      e-commerce
                    </button>
                  ) : null } */}
                </div>
                <div className='flex flex-col items-start gap-3 '>
                  {ecommerce === false ? (
                    <button
                      onClick={porqueKargguLink}
                      className='text-lg font-semibold text-[#333]'
                    >
                      Por qué Karggu?
                    </button>
                  ): null}
                  {ecommerce === false ? (
                    <button
                      onClick={preciosLink}
                      className='text-lg font-semibold text-[#333]'
                    >
                      Precios
                    </button>
                  ): null}
                  {ecommerce === false ? (
                    <button
                      onClick={experienciaLink}
                      className='text-lg font-semibold text-[#333]'
                    >
                      Experiencia
                    </button>
                  ): null}
                  {ecommerce === false ? (
                    <button
                      onClick={blogLink}
                      className='text-lg font-semibold text-[#333]'
                    >
                      Blog
                    </button>
                  ): null}
                </div>
              </div>
              <div className="py-6">
                {ecommerce === false ? (
                  <a
                    href="https://admin.karggu.com" target="_blank" rel="noreferrer"
                    className="-mx-3 block rounded-lg py-2.5 px-3 text-lg font-semibold leading-7 text-[#333] hover:bg-gray-50"
                  >
                    Login
                  </a>
                ) : null }
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}